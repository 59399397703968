export default {
  async update (Vue, id, formData) {
    await Vue.$offline.lordenTrabajoDetalle.updateSync({
      idlorden_trabajo_detalle: id,
      descripcion: formData.descripcion,
      unidades: formData.unidades,
      observacion: formData.observacion
    })
  },
}
